@use "base" as b;

.title {
  font-weight: 700;
  font-family: b.$font-family-title;
}

.hamburger-btn {
  color: b.$bg-main;
  display: none;
  padding: 0.36em;
  min-width: 35.91px;
  border: 1px solid rgb(233, 232, 232);
  border-radius: 0.25em;
  transition: all 0.3s ease-out;
  cursor: pointer;
  z-index: 10;

  &:hover {
    color: white;
    background: b.$menu-btn-hover-bg;
    border: 1px solid b.$menu-btn-hover-bg;
  }

  &:focus {
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: 1px solid b.$bg-header;
  }

  &:active {
    background-color: b.$menu-btn-active-bg;
  }

  @include b.mediaQuery(b.$mobile) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > i {
    font-size: 1.6rem;
  }

  .fa-bars {
    display: inline-block;
  }

  .fa-times {
    display: none;
  }

  &.active {
    .fa-bars {
      display: none;
    }

    .fa-times {
      display: inline-block;
    }
  }
}

#sidebar {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  width: 100vw;
  z-index: 14;
  background: b.$bg-main;
  box-shadow: b.$sidebar-shadow;
  transform: translateY(-150%);
  transition: visibility 0.15s, opacity 0.2s ease-out, transform 0.2s ease-out;

  &.show-sidebar {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
  }

  .navList {
    color: b.$text-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-transform: capitalize;
    z-index: 10;
    border-top: 1px solid rgb(179, 179, 179);
    text-align: center;
    letter-spacing: 0.04em;
    transition: all 0.3s ease-out;
    font-size: 1.1rem;
    box-shadow: b.$sidebar-shadow;

    > li {
      width: 100%;

      &:not(:last-child) {
        border-bottom: 1px solid rgb(38, 50, 83);
      }

      &:hover {
        background: #2d91b9;
      }

      > a {
        display: block;
        padding: 0.8em;
        cursor: pointer;
      }
    }

    @include b.mediaQuery(b.$mobile, min-width) {
      display: none;
    }
  }
}

.divider-intro {
  height: 1px;
  width: 196px;
  margin: 0.75em auto;
  background-color: b.$divider-color;
  position: relative;

  .icon {
    @extend %divider-base;
    border: 1px solid b.$divider-color;
    box-shadow: white 0 0 0 8px;
    color: white;
    background-color: b.$bg-main;
  }
}

.divider {
  @extend %content-divider;
  width: 52%;

  &.lower {
    width: 56px;
  }
}

.underline {
  width: 56px;
  height: 2px;
  background: b.$underline-color-main;
  margin: 1.2em auto;

  &.bright {
    background: b.$underline-color-secondary;
  }
}

.divider-section {
  height: 3px;
  width: 100%;
  margin: 0em auto 0em;
  background-color: white;
  position: relative;
  margin: 2.25em auto;

  .icon {
    @extend %divider-base;
    border: 5px solid white;
    background: b.$bg-main;
    color: b.$text-white;
    z-index: 1;
  }
}

.intro-action-btn {
  @extend %main-btn-defaults;
  background: b.$btn-main-bg;
  margin: 0.6em 0;
  padding: 0.7em 1em;
  border: 1px solid #466bc0;
  font-weight: bold;
  z-index: 1;

  &:visited {
    color: b.$text-white;
  }

  .icon {
    font-size: 1rem;
    margin-left: 0.32em;
  }
}

#scroll-down {
  @extend %bounce-down-animation;
  z-index: 11;
  color: b.$bg-main;
  position: absolute;
  bottom: 1%;
  left: 50%;
  padding: 1em;

  .icon {
    font-size: 1.7rem;
  }
}

.card {
  @extend %project-container;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.02em;
  line-height: 1.5;
  background: white;

  .card-divider {
    @extend %content-divider;
    width: 100%;
  }

  .card-title {
    font-size: 1.5rem;
    width: 100%;
  }

  .card-text-container {
    width: 100%;
  }

  .sub-title {
    font-size: 1rem;
    margin-bottom: 0.3em;
  }

  .code-text {
    letter-spacing: 0.03em;
    background: #eaeaea;
  }

  .second-paragraph {
    margin-top: 0.6em;
  }

  .hidden-text {
    display: none;

    &.show-more-inline {
      display: inline;
    }

    &.show-more-block {
      display: inline-block;
    }
  }

  p a {
    color: b.$link-hover-color;

    &:hover {
      text-decoration: underline;
    }
  }

  .read-more-btn {
    width: 100%;
    font-size: 0.934rem;
    margin: 0.5em 0 0.15em;
    margin: 0.65em 0 0.3em;
    padding: 0.52em 0.8em;
    border-radius: 0.25em;
    background: transparent;
    color: b.$text-white;
    transition: all 0.6s ease-out;
    color: b.$btn-main-bg;
    border: 1px solid b.$btn-main-bg;
    cursor: pointer;

    &:hover {
      background: #ebf5fd;
      border-color: #0f3868;
    }

    &:active {
      box-shadow: inset 0px 0px 5px #d8dddf;
      background: #c6defa;
    }
  }

  .tech-container {
    width: 100%;

    .tech-list {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5em;
      margin: 0.4em 0 0.2em;

      > li {
        font-size: 0.915rem;
        padding: 0.32em 0.9em;
        background: b.$bg-main;
        color: #f8f8f8;
        border-radius: 1.2em;

        @include b.mediaQuery(b.$tablet) {
          padding: 0.3em 0.9em;
        }
      }
    }
  }

  .links-container {
    width: 100%;
    gap: 0.1em 2.5em;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include b.mediaQuery(b.$mobile) {
      gap: 0.1em 1.8em;
    }

    .sub-title {
      grid-area: 1 / 1 / 2 / -1;
      justify-self: start;
    }

    .btn {
      @extend %main-btn-defaults;
      font-weight: bold;
      padding: 0.5em 1em;
      padding: 0.6em 0.8em;
      width: 100%;
      background: b.$btn-main-bg;
    }
  }
}

.project-image-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 1em;

  @include b.mediaQuery(b.$tablet) {
    gap: 0em;
  }

  .project-image {
    @extend %project-container;
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
    transition: box-shadow 0.25s ease-out;

    &:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }

    @include b.mediaQuery(b.$tablet) {
      height: 486px;
    }

    @include b.mediaQuery(b.$mobile) {
      height: 100%;
    }
  }
}

.accordion {
  @extend %content-maxWidth;
  width: 90%;

  @include b.mediaQuery(b.$mobile) {
    width: 100%;
  }

  .accordion-item {
    background-color: white;
    max-width: 788px;
    margin: 0.75em auto;
    border-radius: 0.32em;
    box-shadow: b.$accordion-box-shadow;
  }

  .accordion-item-header {
    padding: 1em 3em 1em 1em;
    min-height: 3.5em;
    line-height: 1.5;
    font-weight: bold;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 0.32em;
    transition: box-shadow 0.35s ease-out;

    &:hover {
      box-shadow: 0px 0px 4px 1px grey;
    }

    &:after {
      content: '\002B';
      font-size: 2rem;
      position: absolute;
      right: 1rem;
    }

    &.active:after {
      content: '\2212';
    }
  }

  .accordion-item-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-out;
  }

  .accordion-item-body-content {
    padding: 1em;
    line-height: 1.5;
    border-top: 1px solid;
    border-image: linear-gradient(to right, #34495e, #72a8af, #34495e) 1;

    a {
      color: b.$link-hover-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .second-paragraph {
    line-height: 1.5;
    margin-top: -0.4em;
    padding: 0 1em 1em;
  }
}

.scroll-top-btn {
  @extend %bounce-animation;
  position: fixed;
  font-size: 1.4rem;
  bottom: 1em;
  right: 1em;
  width: 1.85em;
  height: 1.85em;
  border: none;
  border-radius: 50%;
  background: #fd665c;
  color: white;
  z-index: 10;
  transition: all 0.35s ease-out;
  visibility: hidden;
  cursor: pointer;

  &.show-go-to-top {
    visibility: visible;
    z-index: 12;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgb(250, 130, 130);
  }

  &:hover {
    box-shadow: 0 0 1px 2px rgb(250, 130, 130);
  }

  &:active {
    background: rgb(253, 76, 76);
  }

  @include b.mediaQuery(b.$mobile) {
    font-size: 1.3rem;
    bottom: 0.55em;
    right: 0.55em;
  }
}
